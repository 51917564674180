import 'core-js/stable'
import 'regenerator-runtime/runtime'

import videojs from 'video.js'
import 'video.js/dist/video-js.css' // css
import videojsLang_es from '../video_player/lang/es.json'
import videojsLang_eu from '../video_player/lang/eu.json'
import videojsLang_en from '../video_player/lang/en.json'
const videojsLang = {
  'es': videojsLang_es,
  'eu': videojsLang_eu,
  'en': videojsLang_en
}  

require('videojs-playlist/dist/videojs-playlist.min.js')
import 'videojs-logo'
import 'videojs-logo/dist/videojs-logo.css' // css
import playerLogo from 'video_player/images/irekia_video_logo.png'
import '../video_player/stylesheets/video_player' // css

document.addEventListener('DOMContentLoaded', function(){
  document.addEventListener('video_player.load', event => {
    // console.log('video_player.js > video_player.load')
    window.videojsLoaded = true
    document.querySelectorAll('video.video-js').forEach(function(container){
      videoPlayer.init(container.getAttribute('id'))
    })
      
    if (document.querySelector('div.photo_video_viewer')){
      document.querySelectorAll('div.photo_video_viewer ul.nav-tabs a').forEach(function(elem){
        elem.addEventListener('click', function(event){
          videoPlayer.player.pause()  
        })
      })
    }
  })
  
  // console.log('video_player.js > DOMContentLoaded')
  document.dispatchEvent(new CustomEvent('video_player.load', {'detail': {'container': document}})) 
})

let videoPlayer = {
  player: null,
  containerId: null,
  modal: null,
  clickableCuepoints: [],
  container() {
    return document.getElementById(videoPlayer.containerId)
  },
  playlistContainer() {
    return document.getElementById('videojs_playlist')
  },
  transcriptionContainer() {
    return document.getElementById('videojs_transcription_cues')
  },
  clickableCuesContainer() {
    return document.getElementById('videojs_clickable_cues')
  },
  hasTextTracks(){
    let textTrack = videoPlayer.player.remoteTextTracks()[0]
    return (videoPlayer.transcriptionContainer() != null) || (textTrack != null && textTrack.src != undefined && textTrack.src.match(/\.vtt/) != null)
  },
  isLiveStreaming(){
    let source = videoPlayer.player.options().sources[0]
    return (source != null && source.type != undefined && source.type === 'application/x-mpegURL')
  },
  init(containerId){
    videoPlayer.containerId = containerId

    let locale = document.documentElement.lang
    videojs.addLanguage(locale, videojsLang[locale])

    videoPlayer.player = videojs(videoPlayer.container());

    // Logo
    // add 'logo' attribute to video-js to show logo
    if (videoPlayer.container().getAttribute('logo') != undefined) {
      videoPlayer.player.logo({
        image: playerLogo,
        fadeDelay: null // do not dissappear
      });
    }

    // add playbackRates unless streaming, show playbackRateMenuButton
    if (!videoPlayer.isLiveStreaming()) {
      videoPlayer.player.playbackRates([0.5, 1, 1.5, 2])
    }

    videoPlayer.player.on('ready', function() {
      if (document.querySelectorAll('video[id*=video-js-player]').length == 1) {
        videoPlayer.addModal()
      }
      
      // remove subsCaptsButton from player controls if no textTrack
      if (videoPlayer.hasTextTracks()) {
        videoPlayer.player.controlBar.subsCapsButton.menu.removeChild('CaptionSettingsMenuItem')
      } else {
        videoPlayer.player.controlBar.removeChild('subsCapsButton')
      }

      // use videPlayer.player.trigger('modal-click') to ensure proper modal behavior
      videoPlayer.player.on('modal-click', function(){
        // console.log('enter modal-click')
        if (videoPlayer.modal && videoPlayer.modal.opened()) {
          videoPlayer.modal.close()
        }
        if (videoPlayer.player.paused()){
          videoPlayer.playPromise = videoPlayer.player.play()
          videoPlayer.playPromise.then(_ => {})
          .catch(error => {
            // videoPlayer.player.dispose()
            // videoPlayer.player.trigger('pause')
            // setTimeout(function(){
            //   videoPlayer.player.trigger('modal-click')
            // }, 3000)
            // 
            // Retry until the promise resolves, with delay
            // var t = 500;
            // var max = 5;

            // function rejectDelay(reason) {
            //   return new Promise(function(resolve, reject) {
            //     setTimeout(reject.bind(null, reason), t); 
            //   });
            // }

            // var p = Promise.reject();
            // for(var i=0; i<max; i++) {
            //   p = p.catch(attempt).catch(rejectDelay);
            // }
            // p = p.then(processResult).catch(errorHandler);


            // function attempt() {
            //   var rand = Math.random();
            //   if(rand < 0.8) {
            //     throw rand;
            //   } else {
            //     return rand;
            //   }
            // }
            // function processResult(res) {
            //   console.log(res);
            // }
            // function errorHandler(err) {
            //   console.error(err);
            // }
          })
        }
      })
    })

    // Playlist
    // add class videojs_playlist to clips container
    // add class videojs_item to a
    if (videoPlayer.playlistContainer()) {
      let playlistItems = []
      videoPlayer.playlistContainer().querySelectorAll('a.videojs_item').forEach(function(elem, index){
        // load playlist items
        let item = {
          sources: [{
            src: elem.getAttribute('data-video-src'),
            type: elem.getAttribute('data-video-type'),
          }],
          poster: ''
        }
        if (elem.getAttribute('data-captions')) {
          item.textTracks = [{
            kind: 'captions',
            src: elem.getAttribute('data-captions'),
            default: false
          }]
        }
        playlistItems.push(item)
        // change current playing video
        elem.addEventListener('click', function(event){
          event.preventDefault()
          videoPlayer.player.playlist.currentItem(index)
          videoPlayer.player.trigger('modal-click')
          // TODO: captions are not changing
        })
      })
      // load first item in playlist by default
      videoPlayer.player.playlist(playlistItems, 0)
    }

    // Transcriptions
    if (videoPlayer.transcriptionContainer()) {
      // set video currentTime to cue time when clicking inside transcriptionContainer
      videoPlayer.bindClickableCuepoints(videoPlayer.transcriptionContainer())
      // highlight cuepoints in transcriptionsContainer
      videoPlayer.player.on('loadedmetadata', function() {
        let textTrack = videoPlayer.player.remoteTextTracks()[0]
        let cues = textTrack.cues
        textTrack.oncuechange = function () {
          if (textTrack.activeCues[0] !== undefined) {
            videoPlayer.transcriptionContainer().querySelector('.current_cuepoint').classList.remove('current_cuepoint')
            videoPlayer.transcriptionContainer().querySelector("[data-cue='" + parseInt(textTrack.activeCues[0].startTime) + "']").classList.add('current_cuepoint')
          }
        }
      });
      // highlight first cuepoint in transcriptionsContainer
      videoPlayer.player.on('play', function(){
        videoPlayer.transcriptionContainer().querySelector('.cuepoint:first-child').classList.add('current_cuepoint')
      })
    }
    
    // Clickable cuepoints
    if (videoPlayer.clickableCuesContainer()) {
      // set video currentTime to cue time when clicking in
      videoPlayer.bindClickableCuepoints(videoPlayer.clickableCuesContainer(), true)
      // draw cues above player progress control bar
      videoPlayer.player.on('loadedmetadata', function(){
        videoPlayer.addProgressControlMarkers()
      })
    }

    // videoPlayer.player.on('aderror', function(){
    //   console.log('wait two seconds and retry...')
    //   setTimeout(videoPlayer.play, 2000)
    // });

  },
  addModal(){
    // Add video_overlay in modalDialog
    videoPlayer.modal = videoPlayer.player.createModal('', {
      temporary: true,
      uncloseable: true
    });

    videoPlayer.modal.addClass(`video_overlay_${videoPlayer.player.language()}`)

    videoPlayer.modal.on('click', function(){
      videoPlayer.player.trigger('modal-click')
    })
    if (videoPlayer.hasTextTracks()) {
      videoPlayer.modal.addClass('with_closed_captions')
    }
  },
  bindClickableCuepoints(container, store=false) {
    container.querySelectorAll('[data-cue]').forEach(function(elem){
      let cue = elem.getAttribute('data-cue')
      let vttCue = new VTTCue(cue*1000, cue*1000, '' )
      if (store) { videoPlayer.clickableCuepoints.push(...[vttCue]) }
      elem.addEventListener('click', function(event){
        event.preventDefault()
        videoPlayer.player.currentTime(cue)
        if (videoPlayer.player.paused()){
          videoPlayer.player.trigger('modal-click')
        }
        if (store) {
          // scroll player into view except for transcriptions
          videoPlayer.container().scrollIntoView() 
        }
      })
    })
  },
  addProgressControlMarkers() {
    let videoDuration = videoPlayer.player.duration() * 1000;
    let iMax = videoPlayer.clickableCuepoints.length,
        i,
        playheadWell = document.getElementsByClassName("vjs-progress-control vjs-control")[0];
    // For each cuepoint, dynamically create a div for each and place in progress bar
    for (i = 0; i < iMax; i++) {
      var elem = document.createElement("div");
      elem.className = "vjs-marker";
      elem.id = "marker" + i;
      elem.style.left = videoPlayer.clickableCuepoints[i].startTime / videoDuration * 100 + "%";
      playheadWell.appendChild(elem);
    }
  }
}

export default videoPlayer;
